<template>
  <div>
    <!-- 养老报告详情 -->
    <table class="table">
      <caption class="text-center">养老服务报告</caption>
      <tbody>
      <tr>
        <td class="td1111">
          <div class="userimg float-left">
            <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg"
                 onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">
          </div>
        </td>
        <td colspan="4">
          <div class="tableinfo">
            <div class="frow">
              <div class="fcol w200">
                <label> <span class="font14">姓</span> <span class="font14">名：</span>
                </label> <label><span class="font14"><a>{{ table.oldPersonName }}</a></span></label>
              </div>
              <div class="fcol">
                <label>报告编号：</label> <label><span class="font14"><a>{{ table.assessNo }}</a></span></label>
              </div>

            </div>
            <div class="frow">
              <div class="fcol w200">
                <label>联系电话：</label> <label><span class="font14">{{ table.oldPersonPhone }}</span></label>
              </div>
              <div class="fcol">
                <label><span class="font14">性</span><span class="font14">别：</span></label> <label><span class="font14">{{ table.oldPersonSex }}</span></label>
              </div>
            </div>
            <div class="frow">
              <div class="fcol">
                <label>出生日期：</label> <label><span class="font14">{{ table.oldPersonBirthTime }}</span></label>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">评估机构：</span></td>
        <td class="text-left" colspan="2"><span class="font14">{{ table.assessOrgName }}</span></td>
        <td class="text-right"><span class="font14">类型：</span></td>
        <td class="text-left" style="min-width: 55px"><span class="font14">{{ assessType }}</span></td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">评估方案：</span></td>
        <td class="text-left" colspan="2"><span class="font14"><a href="#">{{ table.plan }}</a></span></td>
        <td class="text-right"><span class="font14">评估员：</span></td>
        <td class="text-left" style="min-width: 55px">
          <span class="font14">{{ table.workPersonName }}</span><br>
          <!-- <span class="font14">-</span> -->
        </td>
      </tr>
      <tr>
      <tr>
        <td class="text-right" rowspan="4"><span class="font14">各项指标结果：</span>
        </td>
      </tr>
      <tr>
        <td class="text-left" colspan="2">
          <span class="font14">感知觉与沟通{{ table.measureResults.feelLevel }}级</span>
        </td>
        <td class="text-right" style="border-right: 1px solid white">
          <span class="font14">精神状态{{ table.measureResults.feelLevel }}级</span>
        </td>
        <td class="text-left"><span class="font14"></span></td>
      </tr>
      <tr>
        <td class="text-left" colspan="2">
          <span class="font14">日常生活动{{ table.measureResults.lifeLevel }}级</span>
        </td>
        <td class="text-right" style="border-right: 1px solid white">
          <span class="font14">社会参与{{ table.measureResults.societyLevel }}级</span>
        </td>
        <td class="text-left"><span class="font14"></span></td>
      </tr>
      <tr></tr>
      <tr>
        <td class="text-right"><span class="font14">等级变更：</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ table.finalEvalResult }}</span>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">初步评估结果：</span></td>
        <td class="text-left" colspan="4">
        <span class="font14">
            <a>{{ table.preEvalResult }}</a>
        </span>
        </td>
      </tr>

      <tr>
        <td class="text-right"><span class="font14">最终评估结果：</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ table.finalEvalResult }}</span>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">审核意见：</span></td>
        <td class="text-left" colspan="4">
          <span class="font14">{{ table.reviewComments }}</span>
        </td>
      </tr>
      <tr>
        <td class="text-right"><span class="font14">专家意见：</span></td>
        <td class="text-left" colspan="4"><span class="font14">{{ table.expertComments }}</span>
        </td>
      </tr>
      <tr>
        <td colspan="5">
          <div class="bz">
            <!-- <header>注：老人能力初步等级划分标准：</header> -->
            <h2>1 养老资源：</h2>
            <p>附近有唐家花园日照中心，地址：枣园东路2号，该日间照料中心有各种老年活动：交友、棋牌、理疗、健康体检、老年餐等。</p>
            <h2>2 养老政策：</h2>
            <p>莲湖区民政局目前正在进行的养老服务：</p>
            <p>①独居、空巢老人关怀慰问。</p>
            <p>②独居老人智能养老设备安装。</p>
            <p>①独居、空巢老人关怀慰问③适老化改造以及家庭养老床位建设。</p>
            <p>
              您现有的条件满足适老化改造以及家庭养老床位建设的要求，您可以向社区申请。同时，结合您个人生活方面的需求，您可以购买我们提供的养老服务包：每周一次上门家政服务，每周一次集齐活动，每月一次短途旅游。或者购买相关的智能看护设备：门磁开关、紧急呼叫器、智能手环、活动传感仪，防跌倒报警器。</p>

            <h2>3 健康建议：</h2>
            <span style="font-weight: 600;margin-left:10px">高血压：</span><span>高血压是比较常见的一种疾病，它是以动脉血压持续升高为主要表现的慢性病，高血压可以引起心脑肾等重要器官的病变，有时候会出现表严重的后果，那么如何预防和控制呢。</span>
            <p>一、要尽量的少喝酒或者是戒酒，饮酒容易导致血压升高，饮酒使心率增快血管收缩而出现血压升高。如果高血压患者饮酒之后，容易引发脑出血。</p>
            <p>二、要补充钙跟钾，钾可以防止高盐饮食摄入引起的血压升高，对轻型的高血压有明显的降压作用，因为增加钾的摄入量有利于钠的排出，补钙和补钾都有降压的作用，常见的食物有虾、鲜核桃、牛奶、海带、丝瓜等等。</p>
            <p>三，要保持心态平和，不急不躁。</p>
            <p>四，要严格控制饮食中盐的摄入量，做到低盐饮食。</p>
            <span style="font-weight: 600;margin-left:10px">糖尿病：</span>
            <p>
              一、首先要控制体重，不管从预防还是治疗角度，都需要控制好自己的体型，不要超重或者肥胖。如果已经超重、肥胖，就把体重减一下。不管是日常干预的患者，还是糖尿病的防治指南上都显示，当合并肥胖或者超重的糖尿病人群，体重降下来以后，血糖要比之前容易控制的多。</p>
            <p>
              二、平常要选择一些血糖生成指数比较低的食物，尽量少吃烂面条或者稠米汤这些血糖升的很快的主食类食物。因为这些食物吃了以后消化吸收很快，体内就要分泌大量的胰岛素来降低这部分碳水化合物。经常这样就会导致胰岛功能很累，可能功能慢慢就会失代偿，出现血糖不能很好的调控，出现血糖升高现象。</p>
          </div>


        </td>
      </tr>

      <tr>
        <!-- <td class="text-center" colspan="5">
            <div class="btngroup">
                <a class="blue" id="downloadReport">下载养老顾问服务报告</a>
            </div>
        </td> -->
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import UploadImg from '@/components/UploadImg.vue'
import api from '@/store/API/api'
import axios from "axios";

export default {
  components: {
    UploadImg
  },
  data() {
    return {
      table: {}
    }
  },
  created() {
    console.log(this.$route);
    this.queryDetail()
  },
  methods: {
    ...mapActions([
      'reportDetail'

    ]),
    queryDetail() {

      let params = this.$route.query.id

      console.log(params);
      this.reportDetail(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          res.data.measureResults = JSON.parse(res.data.measureResults)
          this.table = res.data
        }
        // console.log(JSON.parse(res.data.measureResults) );
      })
    }
  }
}
</script>

<style scoped>
.servertable {
  position: relative;
}

.backwhite {
  background-color: #fff;
  width: 98%;
  padding: 40px 0 0 50px;
  float: left;
}

.table {
  background-color: transparent;
  max-width: 100%;
  margin-bottom: 20px;
  width: 98%;
  border-spacing: 0;
  border-collapse: collapse;

}

/* :deep .el-descriptions__header{
  display: flex;
  justify-content: center !important;

  font-size: 24px;
} */
/* :deep .el-descriptions__header .el-descriptions__title{
    color: #488fe1;
    font-size: 24px;
    padding: 0;
    font-weight: 400;
}
.img{
  width: 150px;
  height: 150px;
} */
.table tr .td1111 {
  width: 170px;
  padding-left: 24px;
}

.td1111 {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #333;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0;
  box-sizing: border-box;
  font-family: "Microsoft YaHei", "微软雅黑";
  list-style: none;
  outline: none;
  text-decoration: none;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
  width: 170px;
  padding-left: 24px;

}

.float-left {
  float: left;
}

.tableinfo {
  margin-left: 1em;
}

.frow {
  width: 100%;
  display: flex;
  align-items: center;
  float: left;
  display: -moz-box;
  /* display: -webkit-box; */
  display: box;
  -moz-box-align: center;
  -webkit-box-align: center;
  display: -ms-flexbox;
  -ms-flex-align: center;
}

.tableinfo {
  margin-left: 1em;
}

.frow .fcol {
  float: left;
  /* height: 60px; */
  line-height: 60px;
  position: relative;
}

.w200 {
  width: 200px;
}

.font14 {
  font-size: 14px;
}

.table tr td {
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: middle;
  /* border-top: 1px solid #ddd; */
}

.table caption {
  color: #488fe1;
  font-size: 24px;
  padding: 0;
  margin-bottom: 16px;
}

.table tr td {
  vertical-align: middle;
  font-size: 14px;
  height: 50px;
  border: 1px solid #e2e5e7;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

table tr td .bz {
  color: #777676;
  line-height: 31px;
  padding-left: 17px;
  padding-right: 50px;
}

.table tr td .bz h2 {
  font-size: 18px;
  margin: 5px 0px;
  line-height: 1em;
  font-family: inherit;
  font-weight: 500;
  color: inherit;
}

.table tr td .bz p {
  padding-left: 11px;
}

.table .btngroup {
  margin: 15px 0;
}

.table .btngroup .blue {
  background-color: #488fe1;
}

.table .btngroup a {
  color: white;
  font-size: 16px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 6px;
  margin: 0 16px;
}

.text-center {
  text-align: center;
}

.table a, .servertable .table a:visited, .servertable .table a:active, .servertable .table a:link, .servertable .table a:hover {
  color: #5093E2;
  font-size: 14px;
}

.userimg img {
  border: 1px solid #e7e7e7;
  margin-bottom: 10px;
  margin-left: 5px;
}

.servertable .table .btngroup .blue:hover {
  background-color: #3183E3;
}

.servertable .table .btngroup a:hover {
  color: white;
  font-size: 16px;
}

.servertable .table tr td .bz header {
  vertical-align: middle;
  font-size: 14px;
  height: auto;
  border: none;
  padding: 0;
}
</style>  